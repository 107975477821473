import { admin } from '@/settings/Roles';
import Packages from '@/util/PhysicalStore/GeneralInformation';
import Products from '@/util/PhysicalStore/Products';

export default {
	data() {
		return {
			admin,
			packData: new Packages(),
			prodData: new Products(),
			currentPackage: '',
		};
	},
	computed: {
		packages() {
			try {
				return this.packData.data.response.data.data;
			} catch (error) {
				return [];
			}
		},
		pack() {
			try {
				return this.packages.find((item) => item.attributes.code_name === this.currentPackage);
			} catch (error) {
				return {};
			}
		},
		loadingProducts() {
			try {
				return this.prodData.data.loading;
			} catch (error) {
				return true;
			}
		},
		wearablesProducts() {
			try {
				const { data } = this.prodData.data.response.data;
				return data.filter((product) => product.attributes.category_code === 'wearables');
			} catch (error) {
				return [];
			}
		},
		hasWearablesProducts() {
			return !!this.wearablesProducts.length;
		},
		swagProducts() {
			try {
				const { data } = this.prodData.data.response.data;
				return data.filter((product) => product.attributes.category_code === 'swag');
			} catch (error) {
				return [];
			}
		},
		hasSwagProducts() {
			return !!this.swagProducts.length;
		},
		otherProducts() {
			try {
				const { data } = this.prodData.data.response.data;
				return data.filter((product) => product.attributes.category_code === 'other');
			} catch (error) {
				return [];
			}
		},
		isAdminOrCorporate() {
			return this.admin.includes(this.$user.details().type);
		},
	},
	created() {
		if (!this.isAdminOrCorporate) {
			this.packData.getPackages();
			this.prodData.getProducts(this.getStoredCountry());
		}
	},
	methods: {
		discount(product) {
			try {
				if (Object.keys(product.attributes.discount).includes(this.pack.attributes.code_name)) {
					return product.attributes.discount[this.pack.attributes.code_name];
				}
			} catch (error) {
				return {};
			}
			return {};
		},
		hasDiscount(product) {
			return !!(Object.keys(this.discount(product)).length);
		},
		getProductPrice(product) {
			return product.price.formatted_amount;
		},
	},
};
